import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';

import Image from 'next/image';
import Link from '@mui/material/Link';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { css, keyframes } from '@emotion/react';
import TelegramIcon from '@mui/icons-material/Telegram';
import { motion } from 'framer-motion';


interface IHeroWidgetProps {
  locale: string;
}

const HeroWidget: FC<IHeroWidgetProps> = (props: IHeroWidgetProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation(['home', 'common']);

  const glitch = keyframes`
  0% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  14% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.05em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em 0.05em 0 rgba(0, 0, 255, 0.75);
  }
  15% {
    text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
      0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  49% {
    text-shadow: -0.05em -0.025em 0 rgba(255, 0, 0, 0.75),
      0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  50% {
    text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
      0.05em 0 0 rgba(0, 255, 0, 0.75), 0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  99% {
    text-shadow: 0.025em 0.05em 0 rgba(255, 0, 0, 0.75),
      0.05em 0 0 rgba(0, 255, 0, 0.75), 0 -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  100% {
    text-shadow: -0.025em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.025em 0 rgba(0, 255, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 0, 255, 0.75);
  }
  `;


  const glitchCss = css`
    text-transform: uppercase;
    position: relative;
    font-weight: 600;

    text-shadow:
      0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em 0.05em 0 rgba(0, 0, 255, 0.75);
    animation: ${glitch} 500ms infinite;

    span {
      position: absolute;
      top:0;
      left:0;
    }
    span:first-child {
      animation: ${glitch} 666ms infinite;
      color: ${theme.palette.primary.main};
      z-index: 1;
      clip-path: polygon(0 0, 100% 0, 100% 36%, 0 45%);
      transform: translate(-0.015em, 0.0125em);
      opacity: 0.5;
    }
    span:last-child {
      animation: ${glitch} 333ms infinite;
      color: ${theme.palette.primary.main};
      z-index: 1;
      clip-path: polygon(0 80%, 100% 70%, 100% 100%, 0 100%);
      transform: translate(0.025em, 0.025em);
      opacity: 0.8;
    }
    @media (prefers-reduced-motion: reduce) {
      *,
      ::before,
      ::after {
        animation-delay: -1ms !important;
        animation-duration: 1ms !important;
        animation-iteration-count: 1 !important;
        background-attachment: initial !important;
        scroll-behavior: auto !important;
        transition-duration: 0s !important;
        transition-delay: 0s !important;
      }
    }
  `;

  const dots = [
    {
      left: '24%',
      top: '14%',
    },
    {
      left: '10%',
      top: '24%',
    },
    {
      left: '85%',
      top: '62%',
    },
    {
      left: '90%',
      top: '37%',
    },
    {
      left: '41%',
      top: '60%',
    },
    {
      left: '50%',
      top: '26%',
    }
  ];

  return (
    <Box component={'section'} sx={{ flexGrow: 1 }}>
      <Container maxWidth='lg'>
        {
          dots.map((dot, i) => (
            <motion.div
              key={i}
              style={{
                width: 14,
                height: 14,
                borderRadius: 2,
                position: 'absolute',
                zIndex: -1,
                left: dot.left,
                top: dot.top,
              }}
              initial={{ opacity: 0, scale: 0.75 }}
              exit={{ opacity: 0, scale: 1 }}
              animate={{
                opacity: 0.8,
                scale: 1,
                rotate: -360,
                backgroundColor: i % 3 == 0
                  ? [theme.palette.secondary.light, theme.palette.secondary.main, theme.palette.primary.main, theme.palette.primary.light, theme.palette.secondary.light]
                  : [theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.main, theme.palette.secondary.light, theme.palette.primary.light],
              }}
              transition={{ duration: i % 3 == 0 ? 6 : 3, ease: 'linear', repeat: Infinity, repeatType: "reverse", }}
            />
          ))
        }
        <Grid
          container
          spacing={1}
          columns={{ xs: 4, sm: 8, md: 12 }}
          direction={matches ? 'row' : 'column-reverse'}
          justifyContent='space-around'
          alignItems='center'
          sx={{ pt: 4 }}
        >
          <Grid item xs={12} sm={12} md={7} lg={7}>
          <motion.div
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.9 }}
            >
            <Typography component={'h1'} variant={'h3'} gutterBottom>
              <Stack direction={matches ? 'row' : 'column'}
                spacing={1}
                justifyContent='left'
                alignItems='left'
              >
                <span style={{ whiteSpace: 'nowrap' }}>{t('heroWidget.h1')}</span>
                <span style={{ whiteSpace: 'nowrap' }}> {t('heroWidget.Im')} <Typography
                  css={glitchCss}
                  variant={'h2'}
                  component={'span'}
                >
                  <span aria-hidden='true'>{t('heroWidget.name')}</span>
                  <span aria-hidden='true'>{t('heroWidget.name')}</span>
                  <span aria-hidden='true'>{t('heroWidget.name')}</span>
                  {t('heroWidget.name')}.
                </Typography>
                </span>
              </Stack>

            </Typography>
            <Typography component={'p'} variant={'h1'}>
              {t('heroWidget.p1')}
            </Typography>
            <Typography component={'p'} variant={'h1'}>
              {t('heroWidget.p2')}
            </Typography>
            <Button
              LinkComponent={Link}
              color='primary'
              variant='outlined'
              href={`https://t.me/${t('appTelegram', { ns: 'common' })}`} target='_blank' rel="noopener noreferrer"
              sx={{ mt: 2 }}
            >
              <TelegramIcon fontSize='medium' />&nbsp;{t('heroWidget.button')}
            </Button>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} sx={{ textAlign: 'right' }}>
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 0.9 }}
              transition={{
                default: {
                  duration: 5.3,
                  ease: [0, 0.71, 0.2, 1.01]
                },
                scale: {
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  restDelta: 0.01
                }
              }}
            >
              <Image
                loading='eager'
                src='/hero.svg'
                alt={process.env.appName}
                width='450'
                height='350'
              />
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
export default HeroWidget;