import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TaxtScrambles from '../../animations/TaxtScrambles';

import { motion } from 'framer-motion';


interface IBenefitsWidgetProps {
    locale?: string;
}
interface IFadeInWhenVisibleProps {
    children: React.ReactNode
}

const BenefitsWidget: FC<IBenefitsWidgetProps> = (props: IBenefitsWidgetProps) => {
    const theme = useTheme();
    const { t } = useTranslation('home');

    const FadeInWhenVisible = (props: IFadeInWhenVisibleProps) => {
        return (
            <motion.div
                style={{height: '100%'}}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                }}
            >
                {props.children}
            </motion.div>
        );
    }

    return (
        <Container maxWidth='lg' sx={{ mt: 6 }} >
            <Grid
                direction={'row'}
                justifyContent='space-between'
                alignItems='stretch'
                container
                spacing={{ xs: 1, md: 2 }}
            >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FadeInWhenVisible>
                        <Card sx={{ m: 0, px: 4, height: '100%' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'h5' }}
                                sx={{ px: 0, borderBottom: `1px solid ${theme.palette.secondary.dark}` }}
                                avatar={
                                    <RocketLaunchIcon />
                                }
                                title={<TaxtScrambles text={t('BenefitsWidget.Card0.title')} />}
                            />
                            <CardContent sx={{ px: 0 }}>
                                {t('BenefitsWidget.Card0.text')}
                            </CardContent>
                        </Card>
                    </FadeInWhenVisible>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FadeInWhenVisible>
                        <Card sx={{ m: 0, px: 4, height: '100%' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'h5' }}
                                sx={{ px: 0, borderBottom: `1px solid ${theme.palette.secondary.dark}` }}
                                avatar={
                                    <DesignServicesIcon />
                                }
                                title={<TaxtScrambles text={t('BenefitsWidget.Card1.title')} />}
                            />
                            <CardContent sx={{ px: 0 }}>
                                {t('BenefitsWidget.Card1.text')}
                            </CardContent>
                        </Card>
                    </FadeInWhenVisible>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FadeInWhenVisible>
                        <Card sx={{ m: 0, px: 4, height: '100%' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'h5' }}
                                sx={{ px: 0, borderBottom: `1px solid ${theme.palette.secondary.dark}` }}
                                avatar={
                                    <ManageSearchIcon />
                                }
                                title={<TaxtScrambles text={t('BenefitsWidget.Card2.title')} />}
                            />
                            <CardContent sx={{ px: 0 }}>
                                {t('BenefitsWidget.Card2.text')}
                            </CardContent>
                        </Card>
                    </FadeInWhenVisible>
                </Grid>
            </Grid>
        </Container>
    )
}

export default BenefitsWidget;