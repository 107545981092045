import React, { FC } from 'react';
import { useTranslation } from "next-i18next";
import Image from 'next/image';
import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { motion, Variants } from "framer-motion";

import TelegramIcon from '@mui/icons-material/Telegram';
import Button from '@mui/material/Button';

interface Props {
  img: string;
  site: string;
  des0: string;
  des1: string;
}


interface IPortfolioWidgetProps {
  locale?: string;
}

const PortfolioWidget: FC<IPortfolioWidgetProps> = (props: IPortfolioWidgetProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['home', 'common']);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const cardVariants: Variants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 50,
      rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  const hue = (h: number) => `hsl(${h}, 100%, 50%)`;

  const MediaControlCard = ({ img, site, des0, des1 }: Props) => {

    return (
      <Box sx={{
        margin: 'auto',
        maxWidth: '100%',
        paddingBottom: '100px',
      }}>

        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <Box sx={{
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            position: 'relative',
            paddingTop: '20px',
            marginBottom: '-180px',

          }}>
            <Typography component={'h4'} variant={'h5'} sx={{
              color: theme.palette.primary.main,
              textTransform: 'capitalize',
              zIndex: 3,
              py: 0.4,
              px: 1,
              position: 'absolute',
              top: 110,
              left: 4,
            }}>
              {site}
            </Typography>
            <Typography component={'p'} variant={'body1'} sx={{
              position: 'absolute',
              zIndex: 1,
              width: matches ? 300 : 150,
              top: 170,
              left: 14,
            }}
            >
              {des0}
            </Typography>
            {site === 'Ваш будущий сайт' || site === 'You future website'
              ? <Button LinkComponent={Link} variant='outlined' size='small' href={`https://t.me/${t('appTelegram', { ns: 'common' })}`} target='_blank' rel="noopener noreferrer" sx={{
                position: 'absolute',
                zIndex: 1,
                top: 220,
                left: 14,
              }}
              >
                <TelegramIcon fontSize='inherit' />&nbsp;{des1}
              </Button>
              : ''
            }

            <Box sx={{
              background: theme.palette.background.paper,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              clipPath: 'polygon(0 60%, 100% 60%, 100% 100%, 0% 100%)',
              textAlign:'right',
            }} />
            <motion.div variants={cardVariants}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              style={{textAlign:'right',}}
            >
              <Link href={site === 'Ваш будущий сайт' || site === 'You future website' ? '/#contacts' : `https://${site}`} target={site === 'Ваш будущий сайт' || site === 'You future website' ? '_self' : '_blank'} rel="noopener noreferrer" sx={{
                zIndex: 33,
                width: 200,
                height: 240,
                borderRadius: '4px',
                background: theme.palette.background.paper,
                boxShadow: '0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075), 0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075), 0 0 16px hsl(0deg 0% 0% / 0.075)',
                transformOrigin: '10% 50%',
                '&:hover': {
                  cursor: "pointer",
                },
                '> span > img': {
                  borderRadius: '4px',
                }
              }}>
                <Image
                  loading='eager'
                  src={img}
                  alt={site}
                  width={200}
                  height={240}
                />
              </Link>
            </motion.div>
          </Box>
        </motion.div>
      </Box >
    );
  }



  const data: [string, string, string, string][] = [
    [t('PortfolioWidget.site0.img'), t('PortfolioWidget.site0.site'), t('PortfolioWidget.site0.des0'), t('PortfolioWidget.site0.des1')],
    [t('PortfolioWidget.site1.img'), t('PortfolioWidget.site1.site'), t('PortfolioWidget.site1.des0'), t('PortfolioWidget.site1.des1')],
    [t('PortfolioWidget.site2.img'), t('PortfolioWidget.site2.site'), t('PortfolioWidget.site2.des0'), t('PortfolioWidget.site2.des1')],
    [t('PortfolioWidget.site3.img'), t('PortfolioWidget.site3.site'), t('PortfolioWidget.site3.des0'), t('PortfolioWidget.site3.des1')],
    [t('PortfolioWidget.site4.img'), t('PortfolioWidget.site4.site'), t('PortfolioWidget.site4.des0'), t('PortfolioWidget.site4.des1')],
    [t('PortfolioWidget.site5.img'), t('PortfolioWidget.site5.site'), t('PortfolioWidget.site5.des0'), t('PortfolioWidget.site5.des1')]
  ];

  return (
    <Container maxWidth='lg' sx={{ mt: 4, mb: 14 }} >
      <Typography component='h1' variant='h1'>
        {t('PortfolioWidget.title')}
      </Typography>
      <Typography component='p' variant='subtitle1' gutterBottom>
        {t('PortfolioWidget.subtitle')}
      </Typography>
      <Divider sx={{ mt: 2, borderColor: theme.palette.secondary.dark }} />
      <Grid
        direction={'row'}
        justifyContent='space-evenly'
        alignItems='stretch'
        container
        spacing={{ xs: 1, md: 2 }}
      >

        {data.map(([img, site, des0, des1], i) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={i}>
            <MediaControlCard img={img} site={site} des0={des0} des1={des1} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default PortfolioWidget;