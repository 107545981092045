import React, { FC } from 'react';
import { useTranslation } from "next-i18next";
import { useTheme } from '@mui/material/styles';
import { css, keyframes } from '@emotion/react';

import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { motion } from 'framer-motion';

interface IContactsWidgetProps {
    locale?: string;
}

const ContactsWidget: FC<IContactsWidgetProps> = (props: IContactsWidgetProps) => {
    const theme = useTheme();
    const { t } = useTranslation(['common', 'home']);
    const { locale } = props;
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const animatedgradient = keyframes`
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    `;

    const borderCss = css`
    --borderWidth: 2px;
    background: ${theme.palette.background.default};
    position: relative;
    border-radius: var(--borderWidth);

    &::after {
        content: '';
        position: absolute;
        top: calc(-1 * var(--borderWidth));
        left: calc(-1 * var(--borderWidth));
        height: calc(100% + var(--borderWidth) * 2);
        width: calc(100% + var(--borderWidth) * 2);
        background: linear-gradient(60deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main}, ${theme.palette.primary.light}, ${theme.palette.secondary.light}, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark});
        border-radius: calc(2 * var(--borderWidth));
        z-index: -1;
        animation: ${animatedgradient} 3s ease alternate infinite;
        background-size: 300% 300%;
    `;

    const dots = [
        {
            left: '24%',
            top: '114%',
        },
        {
            left: '4%',
            top: '48%',
        },
        {
            left: '100%',
            top: '102%',
        },
        {
            left: '90%',
            top: '37%',
        },
        {
            left: '16%',
            top: '3%',
        }
    ];
    return (
        <Container id='contacts' maxWidth='lg' sx={{ mt: 16, mb: 6, py: 4, position: 'relative', overflow: 'hidden' }} >
            {
                dots.map((dot, i) => (
                    <motion.div
                        key={i}
                        style={{
                            width: 14,
                            height: 14,
                            borderRadius: 2,
                            position: 'absolute',
                            zIndex: -1,
                            left: dot.left,
                            top: dot.top,
                        }}
                        initial={{ opacity: 0, scale: 0.75 }}
                        exit={{ opacity: 0, scale: 1 }}
                        animate={{
                            opacity: 0.8,
                            scale: 1,
                            rotate: -360,
                            backgroundColor: i % 3 == 0
                                ? [theme.palette.secondary.light, theme.palette.secondary.main, theme.palette.primary.main, theme.palette.primary.light, theme.palette.secondary.light]
                                : [theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.main, theme.palette.secondary.light, theme.palette.primary.light],
                        }}
                        transition={{ duration: i % 3 == 0 ? 6 : 3, ease: 'linear', repeat: Infinity, repeatType: "reverse", }}
                    />
                ))
            }
            <Grid
                direction={'row'}
                justifyContent='center'
                alignItems='center'
                container
                spacing={{ xs: 1, md: 2 }}
            >
                <Grid item xs={10} sm={10} md={8} lg={8}>
                    <Box css={borderCss} sx={{
                        pt: 2,
                        pb: 4
                    }}>
                        <Typography component='h1' variant='h1' sx={{ textAlign: 'center' }}>
                            {t('ContactsWidget.title', { ns: 'home' })}
                        </Typography>
                        <Stack direction={matches ? 'row' : 'column'}
                            spacing={4}
                            justifyContent={'center'}
                            alignItems='start'
                            sx={{
                                color: '#fff',
                                fontSize: '1.3rem',
                                py:4,
                                px:6
                            }}
                        >
                            <Stack direction='column' spacing={1}>
                                <Typography sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    color: 'inherit',
                                    fontSize: 'inherit'
                                }}>
                                    <FaceIcon fontSize='inherit' />&nbsp;{t('author')}
                                </Typography>
                                <Link color='inherit' title='Позвонить на телефон' href={`tel:${(t('appPhome') as string).replace(/[^+0-9]/g, '')}`}
                                    sx={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <PhoneIphoneIcon fontSize='inherit' />&nbsp;{t('appPhome')}
                                </Link>
                                <Link color='inherit' title='Отправить электронную почту' href={`mailto:${process.env.appEmail}`}
                                    sx={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <AlternateEmailIcon fontSize='inherit' />&nbsp;{process.env.appEmail}
                                </Link>
                            </Stack>
                            <Stack direction='column' spacing={1}>
                                <Link color='inherit' title='Мой телеграм' href={`https://t.me/${t('appTelegram')}`} target='_blank' rel="noopener noreferrer"
                                    sx={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <TelegramIcon fontSize='inherit' />&nbsp;{t('appTelegram')}
                                </Link>
                                <Link color='inherit' title='Я на facebook' href={`https://www.facebook.com/aelinkov`} target='_blank' rel="noopener noreferrer"
                                    sx={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <FacebookIcon fontSize='inherit' />&nbsp;{'aelinkov'}
                                </Link>
                                <Link color='inherit' title='Я в instagram' href={`https://instagram.com/aelinkov`} target='_blank' rel="noopener noreferrer"
                                    sx={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <InstagramIcon fontSize='inherit' />&nbsp;{'aelinkov'}
                                </Link>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

        </Container >
    )
}

export default ContactsWidget;


