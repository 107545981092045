import type { NextPage } from 'next';
import { GetServerSideProps, GetStaticProps } from 'next';
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import NextLink from 'next/link';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';


import MainLayout from '../components/layouts/MainLayout';
import HeroWidget from '../components/sections/home/HeroWidget';
import BenefitsWidget from '../components/sections/home/BenefitsWidget';
import PostsWidget from '../components/sections/home/PostsWidget';
import AboutWidget from '../components/sections/home/AboutWidget';
import TestimonialsWidget from '../components/sections/home/TestimonialsWidget';

import { IPosts } from '../interfaces/postsInterfaces';

import { getAllPosts } from '../lib/api';
import PortfolioWidget from '../components/sections/home/PortfolioWidget';
import ContactsWidget from '../components/sections/home/ContactsWidget';
import TechnologyWidget from '../components/sections/home/TechnologyWidget';

interface IHomeProps {
  locale: string;
  posts: IPosts[];
}


const Home: NextPage<IHomeProps> = (props: IHomeProps) => {
  const { t } = useTranslation('home');
  const theme = useTheme();

  return (
    <MainLayout
      {...props}
      title={t('metaInfo.title')}
      description={t('metaInfo.description')}
      keywords={t('metaInfo.keywords')}
    >
      <HeroWidget {...props} />
      <BenefitsWidget {...props} />
      <AboutWidget {...props} />
      <TechnologyWidget {...props} />
      <PostsWidget title={t('postsWidget.title')} subtitle={t('postsWidget.subtitle')} {...props} />
      <PortfolioWidget {...props} />
      <ContactsWidget {...props} />
    </MainLayout>
  );
}

export default Home;

export const getStaticProps: GetStaticProps = async (context) => {
  const locale = context.locale as string;


  const fields: Array<keyof IPosts> = [
    'slug',
    'title',
    'date',
    'description',
    'coverImage',
    'tags'
  ]

  const posts = getAllPosts(fields, locale);

  const limitPage = 3;
  const totalPages = 1;

  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale, ['common', 'home'])),
      posts,
      limitPage,
      totalPages,
    }
  }
}